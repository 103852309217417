import BaseService from "../base";

class AppService extends BaseService {
  login = (params, success, error) => {
    this.sendRequest("login", params, success, error);
  };

  loginAdfs = (params, success, error) => {
    this.sendRequest("loginAdfs", params, success, error);
  };

  getMenu = (params, success, error) => {
    this.sendRequest("menu", params, success, error);
  };

  getRouting = (params, success, error) => {
    this.sendRequest("route", params, success, error);
  };

  getAccountAbbrList = (params, success, error) => {
    this.sendRequest("accountAbbrList", params, success, error);
  };

  getTaskGroupList = (params, success, error) => {
    this.sendRequest("taskGroupList", params, success, error);
  };

  getCategoryList = (params, success, error) => {
    this.sendRequest("taskStatusList", params, success, error);
  };

  getTaskStatusList = (params, success, error) => {
    this.sendRequest("categorieList", params, success, error);
  };

  checkStepPermission = (params, success, error) => {
    this.sendRequest("checkStepPermission", params, success, error);
  };

  getPagePermission = async (params, success, error) => {
    await this.sendRequest("getPagePermission", params, success, error);
  };

  getGuidelines = (success, error) => {
    this.sendRequest("getGuidelines", {}, success, error);
  };

  validateToken = (success, error) => {
    this.sendRequest("validateToken", {}, success, error);
  };
}

const appService = new AppService();

export default appService;
